import { useCallback } from "react";
import { ISubscriptionEvent } from "@smartrr/shared/entities/SubscriptionEvent";
import { formatDateTime } from "@vendor-app/app/AdminRoute/AdminSuperUserRoute/utils";
import { CommentForRemovalContainer, ItemWithDateContainer } from "../../../styles";
import { Avatar, LegacyCard, LegacyStack, Text } from "@shopify/polaris";

export const useRenderCommentForRemoval = () => {
  const renderCommentForRemoval = useCallback((event: ISubscriptionEvent) => {
    const [day, time] = formatDateTime(event.eventDate);

    const initials = event.eventData?.comment?.authorName
      .split(" ")
      .map(name => name[0])
      .join("");

    return (
      <CommentForRemovalContainer>
        <LegacyCard sectioned>
          <LegacyStack vertical>
            <LegacyStack distribution="equalSpacing">
              <Text
                variant="bodyMd"
                as="span"
                color="subdued"
                fontWeight="semibold"
                id="subscription-events__remove-comment-section__day"
              >
                {day.toLocaleUpperCase()}
              </Text>
            </LegacyStack>
            <ItemWithDateContainer>
              <Text variant="bodyMd" as="span" id="subscription-events__remove-comment-section__text">
                &quot;{event.eventData?.comment?.text}&quot;
              </Text>
              <Text
                variant="bodyMd"
                as="span"
                color="subdued"
                id="subscription-events__remove-comment-section__time"
              >
                {time.toLocaleUpperCase()}
              </Text>
            </ItemWithDateContainer>
            <LegacyStack spacing="extraTight" alignment="center">
              <Avatar size="extraSmall" name={event.eventData?.comment?.authorName} initials={initials} />
              <Text variant="bodySm" as="span" id="subscription-events__remove-comment-section__comment-by">
                Comment by {event.eventData?.comment?.authorName}
              </Text>
            </LegacyStack>
          </LegacyStack>
        </LegacyCard>
      </CommentForRemovalContainer>
    );
  }, []);
  return renderCommentForRemoval;
};
