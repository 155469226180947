import {
  Button,
  Icon,
  LegacyStack,
  Modal,
  Scrollable,
  Spinner,
  Text,
  TextField,
  Tooltip,
} from "@shopify/polaris";
import { InfoMinor } from "@shopify/polaris-icons";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import {
  ISubscriptionEvent,
  ISubscriptionEventWithPurchaseStateInformation,
} from "@smartrr/shared/entities/SubscriptionEvent";
import { toViewDate } from "@smartrr/shared/utils/renderViewDate";
import { groupBy } from "lodash";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { useVendorPortalVariantToPurchasableMap } from "@vendor-app/app/_state/reducers/purchasables";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { isRewardEvent, isSubscriptionEvent, isValidRewardEvent } from "./libs/constants";

import {
  EventsHeader,
  EventsList,
  EventsWrapper,
  ItemWithDateContainer,
  ShopifyEvent,
  StartOfEventsLi,
  StyledDate,
  StyledEvent,
  formatEventDescription,
  isPinnedComment,
  isShopifyComment,
  IRewardEvent,
  JoinedEvent,
  IEventRewardProgramUnits,
  getEventDate,
} from "./libs";
import { useRenderCommentForRemoval } from "./libs/utils/hooks/useRenderCommentForRemoval";
import { useRenderRewardEvent } from "./libs/utils/hooks/useRenderRewardEvent";
import { useRenderSubscriptionEvent } from "./libs/utils/hooks/useRenderSubscriptionEvent";

interface Props {
  subscriptionEvents: ISubscriptionEventWithPurchaseStateInformation[];
  loading: boolean;
  shopifyEvents?: ShopifyEvent[];
  rewardEvents?: IRewardEvent[];
  rewardProgramUnits?: IEventRewardProgramUnits;
  hideComments?: boolean;

  addComment?: (text: string, authorName: string) => void;
  pinComment?: (eventId: string) => void;
  removeComment?: (eventId: string) => void;
}

export const Events = ({
  subscriptionEvents,
  shopifyEvents,
  rewardEvents,
  rewardProgramUnits,
  loading,
  hideComments = false,
  addComment,
  pinComment,
  removeComment,
}: Props) => {
  const currentUser = useSmartrrVendorSelector(state => state.auth.user);
  const vntToPurchasableMap = useVendorPortalVariantToPurchasableMap();
  const { addToast } = useToast();
  const renderCommentForRemoval = useRenderCommentForRemoval();
  const renderRewardEvent = useRenderRewardEvent();
  const renderSubscriptionEvent = useRenderSubscriptionEvent();
  const [comment, setComment] = useState<string | null>(null);
  const [commentSelectedForRemoval, setCommentSelectedForRemoval] = useState<ISubscriptionEvent | null>(null);
  const [openRemoveCommentModal, setOpenRemoveCommentModal] = useState(false);
  const [joinedEvents, setJoinedEvents] = useState<JoinedEvent[]>([]);

  const handleChangeComment = (comment: string) => {
    setComment(comment);
  };

  const handleAddComment = useCallback(() => {
    if (!comment) {
      addToast("Please add a comment");
      return;
    }
    setComment(null);
    addComment?.(comment, `${currentUser?.originalFirstName} ${currentUser?.originalLastName}`);
  }, [comment]);

  const handleRemoveComment = useCallback(
    (event: ISubscriptionEvent) => {
      setCommentSelectedForRemoval(event);
      setOpenRemoveCommentModal(true);
    },
    [comment]
  );

  const organization = useActiveOrganizationSelector();
  const formatDateTime = (date: ISODateString) => {
    const day = ISODateString.fromString(date).setZone(organization?.billingTimezone);
    const formattedDay = day.toLocaleString(DateTime.DATE_FULL);
    const time = day.toLocaleString({
      hour: "2-digit",
      minute: "2-digit",
    });
    return [formattedDay, time];
  };

  // Filtering out invalid reward events
  const filteredJoinedEvents = useMemo(() => {
    return joinedEvents.filter(event => {
      if (isRewardEvent(event) && isValidRewardEvent(event)) {
        return event;
      }

      if (isSubscriptionEvent(event)) {
        return event;
      }

      if (isShopifyComment(event)) {
        return event;
      }
    });
  }, [joinedEvents]);

  const eventsGroupedByDate = useMemo(() => {
    // Sorting by time in descending order
    const eventsSortedByTime = filteredJoinedEvents.sort((a, b) => {
      const firstDate = getEventDate(a);
      const secondDate = getEventDate(b);
      if (firstDate > secondDate) {
        return -1;
      }

      if (firstDate < secondDate) {
        return 1;
      }

      return 0;
    });
    const groupedEvents = groupBy(
      [
        ...eventsSortedByTime.filter(event => !isPinnedComment(event as ISubscriptionEvent)),
        ...(shopifyEvents ?? []),
      ],
      event => {
        const date = getEventDate(event);
        return toViewDate(ISODateString.fromString(date).toJSDate(), {
          day: "numeric",
          month: "long",
          year: "numeric",
        });
      }
    );

    return Object.keys(groupedEvents)
      .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
      .reduce(
        (acc, key) => {
          acc[key] = groupedEvents[key];
          return acc;
        },
        {} as Record<string, any>
      );
  }, [shopifyEvents, joinedEvents]);

  const renderEvent = useCallback(
    (event: JoinedEvent | ShopifyEvent) => {
      if (isShopifyComment(event)) {
        const [, time] = formatDateTime(event.createdAt);

        return (
          <StyledEvent key={event.id}>
            <ItemWithDateContainer>
              <span dangerouslySetInnerHTML={{ __html: formatEventDescription(event, vntToPurchasableMap) }} />
              <Text variant="bodyMd" as="span" color="subdued" id="subscription-events__event-section__time">
                {time}
              </Text>
            </ItemWithDateContainer>
          </StyledEvent>
        );
      }
      // isRewardEvent is used as a type guard
      const shouldRenderRewardEvent = isRewardEvent(event) && isValidRewardEvent(event) && rewardProgramUnits;
      if (shouldRenderRewardEvent) {
        return renderRewardEvent(event, rewardProgramUnits);
      }
      /**
       * Adding this condition as typescript did not recognize
       * the event as ISubscriptionEvent even after the
       * isRewardEvent type guard.
       */
      if (isSubscriptionEvent(event)) {
        return renderSubscriptionEvent({
          event,
          vntToPurchasableMap,
          pinComment,
          onRemoveComment: handleRemoveComment,
        });
      }
    },
    [eventsGroupedByDate, rewardProgramUnits]
  );

  /**
   * We're joining subscription events and otp events which
   * are later grouped by date in eventsGroupedByDate
   */
  const joinEvents = useCallback(() => {
    if (rewardEvents?.length) {
      const combinedEvents: JoinedEvent[] = [...rewardEvents, ...subscriptionEvents];
      setJoinedEvents(combinedEvents);
    } else {
      setJoinedEvents(subscriptionEvents);
    }
  }, [subscriptionEvents, rewardEvents]);

  useEffect(() => {
    joinEvents();
  }, [subscriptionEvents, rewardEvents]);

  return (
    <EventsWrapper>
      <EventsHeader>
        <Text variant="headingMd" as="h4" fontWeight="bold">
          Event log
        </Text>
        {hideComments ? null : (
          <TextField
            id="subscription-events__add-comment-section__comment-textfield"
            label=""
            labelHidden
            autoComplete="off"
            placeholder="Leave an internal comment on this subscription..."
            value={comment ?? ""}
            onChange={handleChangeComment}
            connectedRight={<Button onClick={() => handleAddComment()}>Post</Button>}
          />
        )}
        <div />
      </EventsHeader>
      <Scrollable style={{ height: "340px" }}>
        {loading ? (
          <LegacyStack alignment="center" distribution="center">
            <Spinner />
          </LegacyStack>
        ) : joinedEvents.length ? (
          <EventsList>
            {joinedEvents
              .filter(event => {
                if (isSubscriptionEvent(event)) {
                  return isPinnedComment(event);
                }
              })
              .map(event => renderEvent(event))}
            {Object.keys(eventsGroupedByDate).map((key: string) => {
              return (
                <React.Fragment key={key}>
                  <StyledDate>
                    <Text
                      variant="bodyMd"
                      as="p"
                      color="subdued"
                      fontWeight="semibold"
                      id="subscription-events__add-comment-section__day-for-group"
                    >
                      {key.toLocaleUpperCase()}
                    </Text>
                  </StyledDate>
                  {eventsGroupedByDate[key].map(event => renderEvent(event))}
                </React.Fragment>
              );
            })}
            <StartOfEventsLi>
              <Tooltip
                dismissOnMouseOut
                preferredPosition="above"
                content="Only events created after February 17th, 2022 will be present in this timeline."
              >
                <LegacyStack spacing="tight">
                  <Text as="p" variant="bodyMd">
                    Start of events
                  </Text>
                  <Icon source={InfoMinor} color="base" />
                </LegacyStack>
              </Tooltip>
            </StartOfEventsLi>
          </EventsList>
        ) : (
          <LegacyStack vertical distribution="center" alignment="center">
            <Text variant="headingMd" as="h4" fontWeight="bold">
              No events yet
            </Text>
          </LegacyStack>
        )}
      </Scrollable>
      {commentSelectedForRemoval == null ? null : (
        <Modal
          sectioned
          title="Delete comment?"
          open={openRemoveCommentModal}
          onClose={() => setOpenRemoveCommentModal(false)}
          primaryAction={{
            id: "subscription-events__remove-comment-modal__confirm-btn",
            content: "Confirm delete",
            onAction() {
              removeComment?.(commentSelectedForRemoval.id);
              setOpenRemoveCommentModal(false);
            },
            destructive: true,
            loading,
          }}
          secondaryActions={[
            {
              id: "subscription-events__remove-comment-modal__cancel-btn",
              content: "Cancel",
              onAction() {
                setOpenRemoveCommentModal(false);
                setCommentSelectedForRemoval(null);
              },
              loading,
            },
          ]}
        >
          {renderCommentForRemoval(commentSelectedForRemoval)}
        </Modal>
      )}
    </EventsWrapper>
  );
};
