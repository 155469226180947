import React, { useCallback } from "react";
import { Avatar, Button, Icon, LegacyCard, LegacyStack, Text } from "@shopify/polaris";
import { ISubscriptionEvent, SubscriptionEventTypeEnum } from "@smartrr/shared/entities/SubscriptionEvent";
import { formatEventDescription, isPinnedComment } from "../..";
import { formatDateTime } from "@vendor-app/app/AdminRoute/AdminSuperUserRoute/utils";
import {
  ButtonContainer,
  ItemWithDateContainer,
  StyledEvent,
  PinnedComment,
  StyledComment,
} from "../../../styles";
import { MappedPurchasableVariant } from "@smartrr/shared/utils/useVariantToPurchasableMap";
import { isSubscriptionEvent } from "../../../constants";
import { useIsSuperUserSelector } from "@vendor-app/app/_state/reducers/auth";
import { SuperuserBadge } from "@smartrr/shared/components/SuperuserBadge";
import { PinMinor, DeleteMinor } from "@shopify/polaris-icons";

interface RenderEventProps {
  event: ISubscriptionEvent;
  vntToPurchasableMap: MappedPurchasableVariant;
  pinComment?: (eventId: string) => void;
  onRemoveComment: (event: ISubscriptionEvent) => void;
}

export const useRenderSubscriptionEvent = () => {
  const isSuperUser = useIsSuperUserSelector();
  const renderEvent = useCallback(
    ({ event, vntToPurchasableMap, pinComment, onRemoveComment }: RenderEventProps) => {
      /**
       * Adding this condition as typescript did not recognize
       * the event as ISubscriptionEvent even after the
       * isRewardEvent type guard.
       */
      if (isSubscriptionEvent(event)) {
        const [day, time] = formatDateTime(event.eventDate);
        const isPinned = isPinnedComment(event);

        if (event.eventType === "ADMIN_ADDED_COMMENT") {
          const CommentComponent = isPinned ? PinnedComment : StyledComment;

          const initials = event.eventData?.comment?.authorName
            .split(" ")
            .map(name => name[0])
            .join("");

          return (
            <CommentComponent key={event.id} id="subscription-events__comment-section">
              <LegacyCard sectioned>
                <LegacyStack vertical>
                  <LegacyStack distribution="equalSpacing">
                    <Text
                      variant="bodyMd"
                      as="span"
                      color="subdued"
                      fontWeight="semibold"
                      id="subscription-events__comment-section__day"
                    >
                      {day.toLocaleUpperCase()}
                    </Text>
                    <ButtonContainer>
                      <div
                        onClick={() => pinComment?.(event.id)}
                        style={{ cursor: "pointer" }}
                        id="subscription-events__comment-section__pinned-comment"
                      >
                        <Icon source={PinMinor} color={isPinned ? "warning" : "subdued"} />
                      </div>
                      <Button plain icon={DeleteMinor} onClick={() => onRemoveComment(event)} />
                    </ButtonContainer>
                  </LegacyStack>
                  <ItemWithDateContainer>
                    <Text variant="bodyMd" as="span" id="subscription-events__comment-section__comment-text">
                      &quot;{event.eventData?.comment?.text}&quot;
                    </Text>
                    <Text
                      variant="bodyMd"
                      as="span"
                      color="subdued"
                      id="subscription-events__comment-section__comment-day"
                    >
                      {time.toLocaleUpperCase()}
                    </Text>
                  </ItemWithDateContainer>
                  <LegacyStack spacing="extraTight" alignment="center">
                    <Avatar size="extraSmall" name={event.eventData?.comment?.authorName} initials={initials} />
                    {isPinned ? (
                      <Text
                        variant="bodySm"
                        as="span"
                        color="warning"
                        id="subscription-events__comment-section__pinned-by"
                      >
                        Pinned comment by {event.eventData?.comment?.authorName}
                      </Text>
                    ) : (
                      <Text
                        variant="bodySm"
                        as="span"
                        color="subdued"
                        id="subscription-events__comment-section__commented-by"
                      >
                        Comment by {event.eventData?.comment?.authorName}
                      </Text>
                    )}
                  </LegacyStack>
                </LegacyStack>
              </LegacyCard>
            </CommentComponent>
          );
        }

        if (event.eventType === SubscriptionEventTypeEnum.SUPER_USER_LOGGEDIN_AS_CUSTOMER) {
          if (isSuperUser) {
            <StyledEvent key={event.id}>
              <ItemWithDateContainer>
                <Text variant="bodyMd" as="span" id="subscription-events__event-section__text">
                  <SuperuserBadge />
                  {formatEventDescription(event, vntToPurchasableMap)}
                </Text>
                <Text variant="bodyMd" as="span" color="subdued" id="subscription-events__event-section__time">
                  {time}
                </Text>
              </ItemWithDateContainer>
            </StyledEvent>;
          } else {
            return <React.Fragment />;
          }
        }
        return (
          <StyledEvent key={event.id}>
            <ItemWithDateContainer>
              <Text variant="bodyMd" as="span" id="subscription-events__event-section__text">
                {formatEventDescription(event, vntToPurchasableMap)}
              </Text>
              <Text variant="bodyMd" as="span" color="subdued" id="subscription-events__event-section__time">
                {time}
              </Text>
            </ItemWithDateContainer>
          </StyledEvent>
        );
      }
    },
    []
  );
  return renderEvent;
};
