import { useCallback } from "react";
import { IRewardEvent, IEventRewardProgramUnits } from "../../../types";
import { formatDateTime } from "@vendor-app/app/AdminRoute/AdminSuperUserRoute/utils";
import { StyledEvent, ItemWithDateContainer } from "../../../styles";
import { Text } from "@shopify/polaris";
import { formatRewardEventDescription } from "../..";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

export const useRenderRewardEvent = () => {
  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);
  const renderRewardEvent = useCallback((event: IRewardEvent, units: IEventRewardProgramUnits) => {
    const [, time] = formatDateTime(event.createdDate);

    return (
      <StyledEvent key={event.id}>
        <ItemWithDateContainer>
          <Text variant="bodyMd" as="span">
            {formatRewardEventDescription(event, units, purchasables)}
          </Text>
          <Text variant="bodyMd" as="span" color="subdued">
            {time}
          </Text>
        </ItemWithDateContainer>
      </StyledEvent>
    );
  }, []);
  return renderRewardEvent;
};
