import { ISubscriptionEvent } from "@smartrr/shared/entities/SubscriptionEvent";
import { IRewardEvent } from "./types";

export const isSubscriptionEvent = (event: IRewardEvent | ISubscriptionEvent): event is ISubscriptionEvent =>
  (event as ISubscriptionEvent).eventDate !== undefined;

export const isRewardEvent = (event: IRewardEvent | ISubscriptionEvent): event is IRewardEvent =>
  (event as IRewardEvent).createdDate !== undefined;

export const isValidRewardEvent = (event: IRewardEvent): event is IRewardEvent => {
  if (isRewardEvent(event)) {
    switch (event.type) {
      case "OTP_REDEEM_PRODUCT": {
        return true;
      }
      case "OTP_REDEEM_INCENTIVE": {
        return true;
      }
      case "MANUAL": {
        return true;
      }
      default: {
        return false;
      }
    }
  }
  return false;
};
